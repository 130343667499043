import React, { useState, useEffect } from "react";
import "./Course.scss";
import ProjectApprovedMessages from "./ProjectApprovedMessages";
import { useSelector, } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { IconButton } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Tabs, Box, Tab } from "@mui/material";
import { ListItem, } from "@material-ui/core";
import Done from "../../assets/svgjs/Done";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import ProjectFile from "./ProjectFile";
import Skeleton from "@material-ui/lab/Skeleton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Reply from "../../assets/svgjs/Reply";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@mui/icons-material/Done";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClockOrange from "../../assets/svgjs/ClockOrange";
import SendIcon from "@mui/icons-material/Send";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import DirectionsIcon from "@mui/icons-material/Directions";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "fit-content",
    flexShrink: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },

  tooltipFull: {
    fontSize: 30,
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "#000",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeButtonExit: {
    color: "lightgray",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  videoSection: {
    padding: "2%",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "12px",
    border: "1px solid lightgray",
    borderRadius: "1rem",
    padding: "0rem 0.5rem",
    height: "2rem",
  },
  flexClass: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "center",
    padding: "50px",
  },
  mainBlock: {
    margin: "0rem 0.5rem 1.5rem!important",
    boxShadow:
      "0px 0px 2px 2px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 290px 0px rgb(0 0 0 / 2%)",
    "&:before": {
      backgroundColor: "white",
    },
    "& > div:first-child": {
      boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 10%)",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0 1rem" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Project = (props) => {
  let { nuggets, isLoading, projectNugget, courseUserProgress} =
    props;
  const [value, setValue] = React.useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrlName, setFileUrlName] = useState("");
  const [projectObjDetails, setProjectObjDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentObjid, setcurrentObjid] = useState();
  const [openIndex, setOpenIndex] = useState(null);
  const [showReply, setShowReply] = useState(null);
  const [showAllMessage, setShowAllMessage] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [lockView, setLockView] = useState(false);
  const [fileOpen, setFileOpen] = useState(false);
  const [objFile, setObjFile] = useState();
  const [seeApprovedMessages, SetSeeApprovedMessages] = useState([]);
  const [showApprovedMessages, SetShowApprovedMessages] = useState(false);
  const [groupedMessages, setGroupedMessages] = useState([]);
  const hiddenFileInput = React.useRef(null);
  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprgcou;


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const classes = useStyles();
  useEffect(() => {

    const fetchData = async () => {
      setLoading(false);

      try {
        if (value != undefined) {
          callApiRepeat(value);
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, [value]);
  async function callApi(tabValue) {
    setProjectObjDetails([]);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bcid: lcourseDetails.bcid,
        objid: tabValue,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserProjectObjects",
        bodyParam
      );
      setLoading(false);
      if (response != null || response != undefined) {
        const groupedMessages = response.reduce((acc, message) => {
          const date = message.postedDate;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(message);
          return acc;
        }, {});


        setGroupedMessages(groupedMessages);

        setProjectObjDetails(response);
        setAllMessages(response);
      }

    } catch (error) {
      console.error(error);
    }
  }
  async function callApiRepeat(tabValue) {

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bcid: lcourseDetails.bcid,
        objid: tabValue,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserProjectObjects",
        bodyParam
      );
      setLoading(false);
      if (response != null || response != undefined) {
        const groupedMessages = response.reduce((acc, message) => {
          const date = message.postedDate;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(message);
          return acc;
        }, {});
        setGroupedMessages(groupedMessages);
        setProjectObjDetails(response);
        setAllMessages(response);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleChangeSideTab = (event, newValue, oid) => {
    setcurrentObjid(newValue);
    setValue(newValue);

    for (let i = 0; i < projectNugget.length; i++) {
      for (let j = 0; j < projectNugget[i].objects.length; j++) {
        if (projectNugget[i].objects[j].oid == newValue) {
          if (projectNugget[i].objects[j].userOP.op == 0) {
            setLockView(true);
          } else {
            setLockView(false);
            callApi(newValue);
          }
        }
      }
    }
  };

  const handleMessageChange = (event, newValue, oid) => {
    setErrMessage("");
    setMessage(event.target.value);
  };

  const handleReplyMessageChange = (event, newValue, oid) => {
    setReplyMessage(event.target.value);
  };

  const postReply = (id, postid, type) => {
    let replyobj = {};
    replyobj.id = postid;
    replyobj.uname = userDetails.name;
    replyobj.message = replyMessage;

    projectObj(id, type, replyobj);
  };

  async function projectObj(obj, type, replyobj) {

    let id = new Date().getTime();
    let projectObj = {};

    if (type == undefined) {
      if (
        message === "" ||
        message === null ||
        (message != undefined && message.trim() === "")
      ) {
        setErrMessage("Please enter Message");
        return;
      }

      if (selectedFile != undefined) {
        if (selectedFile.size > 50 * 1024 * 1024) {
          setErrMessage("File size is greater than 50 MB");
          return;
        }
        let extend =
          selectedFile.name.substring(
            selectedFile.name.lastIndexOf(".") + 1,
            selectedFile.name.length
          ) || selectedFile.name;



        if (
          extend !== "JPG" &&
          extend !== "PNG" &&
          extend !== "JPEG" &&
          extend !== "jpg" &&
          extend !== "png" &&
          extend !== "jpeg" &&
          extend !== "pdf"
        ) {
          setErrMessage(
            "Please upload files in the format of pdf, jpg, jpeg, png only."
          );

          return;
        }
      }
      setLoading(true);
      projectObj.id = id;
      projectObj.uname = userDetails.name;
      projectObj.message = message;
      projectObj.fileName = fileName;
      projectObj.fileUrlName = fileUrlName;
    }
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        bcid: lcourseDetails.bcid,
        projectObj: projectObj,
        objid: obj,
        replyobj: replyobj,
        type: type,
        bpid: lcourseDetails.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };


    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateUserProjectObjects",
        bodyParam
      );

      setLoading(false);
    } catch (error) {
      console.error(error);
    }

    setMessage("");
    setErrMessage("");
    setReplyMessage("");
    setOpenIndex(null);
    setShowReply(null);
    setErrMessage("");
    setSelectedFile(null);
    setFileName("");
    setFileUrlName("");
    callApi(obj);
  }

  async function handleImageChange(event, oid) {
    setSelectedFile(event.target.files[0]);

    setFileName(event.target.files[0].name);
    let pfileName = new Date().getTime();
    let filename = pfileName + event.target.files[0].name;
    setFileUrlName(filename);
    let filetype = event.target.files[0].type;
    if (event.target.files[0]) {
      const bodyParam = {
        body: {
          type: "project",
          filename: filename,
          filetype: filetype,
          oid: config.aws_org_id,
          eid: userDetails.eid,
          bcid: lcourseDetails.bcid,
          objid: oid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      setLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );
        fileUpload(event.target.files[0], response);
      } catch (error) {
        console.error("getCategoryError", error);
      }
    }
  }

  async function fileUpload(file, url) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then(() => {

      })
      .catch((err) => {
        console.error(err);
      });

    setLoading(false);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      projectObj(currentObjid);
    }
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const toggleReply = (index) => {
    setShowReply(showReply === index ? null : index);
  };

  function formatDate(dateString) {

    const [day, month, year] = dateString.split("/");

    const monthIndex = parseInt(month, 10) - 1;

    const date = new Date(year, monthIndex, day);

    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    const formattedDate = formatter.format(date);
    return formattedDate;
  }

  function openFile(fileObj) {
    setObjFile(fileObj);
    handleFileOpen();
  }
  const handleFileOpen = () => {
    setFileOpen(true);
  };

  const handleFileClose = () => {
    setFileOpen(false);
  };

  const downloadFile = async (obj) => {

    let downloadUrl = `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-data/data/project/${userDetails.eid}/${lcourseDetails.bcid
      }/${value}/${obj.fileUrlName}`;


    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = obj.fileName || "download";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };

  const handleApprovedMessageOpen = () => {
    SetShowApprovedMessages(true);
  };

  const handleApprovedMessageClose = () => {
    SetShowApprovedMessages(false);
  };
  async function getProjectApprovedMessage() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: lcourseDetails.bpid,
        tid: lcourseDetails.tid,
        btid: lcourseDetails.bcid,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };


    setLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getProjectApprovedMessage",
        bodyParam
      );

      SetSeeApprovedMessages(response);
      setLoading(false);
      handleApprovedMessageOpen();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={fileOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={fileOpen}>
          <ProjectFile
            handleFileClose={handleFileClose}
            obj={objFile}
            objId={value}
            bcid={lcourseDetails.bcid}
          />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showApprovedMessages}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showApprovedMessages}>
          <ProjectApprovedMessages
            handleFileClose={handleApprovedMessageClose}
            seeApprovedMessages={seeApprovedMessages}
            nuggets={nuggets}
            projectNugget={projectNugget}
            callApi={callApi}
          />
        </Fade>
      </Modal>

      <Typography component="div" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <Typography component="div" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <Typography component="div" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      <Typography component="div" variant="h1">
        {isLoading ? <Skeleton /> : null}
      </Typography>
      {Object.keys(nuggets).map((nugget, idx) => (
        <>
          {nuggets[nugget].map(
            ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
              <Accordion className={classes.mainBlock}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <h3
                        className="item"
                        style={{
                          fontWeight: "bold",
                          color: nenabled
                            ? objects[0].userOP.op == 1 ||
                              objects[0].userOP.op == 2
                              ? "#e35f14"
                              : "black"
                            : "gray",
                        }}
                      >
                        {ntitle}
                      </h3>

                      {nenabled == false ||
                        (objects[0].userOP.op != 0 && (
                          <AccessTimeIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#e35f14",
                            }}
                          />
                        ))}

                      {nenabled == false && (
                        <LockIcon
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "gray",
                          }}
                        />
                      )}

                    </div>
                  </Typography>
                </AccordionSummary>
                {courseUserProgress == 0 ||
                  (nenabled && (
                    <AccordionDetails style={{ display: "block" }}>
                      <Typography>
                        <div>
                          {lcourseDetails?.coursetype == "Project" ? (
                            <Box
                              sx={{
                                flexGrow: 1,
                                bgcolor: "background.paper",
                                display: "flex",
                              }}
                            >
                              <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChangeSideTab}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: "divider" }}
                                style={{ width: "27rem" }}
                              >
                                {objects.map(
                                  ({
                                    oid,
                                    otitle,

                                    userOP,
                                  }) => (
                                    <Tab
                                      key={oid}
                                      label={
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            width: "100%",
                                            justifyContent: "Space-between",
                                            textTransform: "capitalize",
                                            alignItems: "center",
                                            color: "black",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {otitle}
                                          </p>
                                          <span>
                                            {userOP.op === 2 ? (
                                              <span>
                                                {userOP.op === 2 ? (
                                                  <Done
                                                    className="Done_size"
                                                    cls1="cls1_D"
                                                    cls2="cls2_D"
                                                  />
                                                ) : null}
                                              </span>
                                            ) : null}
                                          </span>
                                        </div>
                                      }
                                      value={oid}
                                      style={{
                                        fontSize: "12px",
                                        alignItems: "start",
                                      }}
                                    />
                                  )
                                )}
                              </Tabs>

                              {loading ? (
                                <Skeleton
                                  style={{
                                    width: "60vw",
                                    margin: "-33px 7px -20px 7px",
                                  }}
                                />
                              ) : (
                                <>
                                  {objects.map((object, index) => (
                                    <TabPanel
                                      key={index}
                                      value={value}
                                      index={object.oid}
                                      style={{
                                        width: "60vw",
                                        textAlign: "center",
                                      }}
                                    >
                                      {lockView ? (
                                        <div>
                                          <h2>
                                            Please Complete previous object.
                                          </h2>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            border: "1px solid lightgray",
                                            overflow: "hidden",
                                            borderRadius: "8px",
                                            marginBottom: "2rem",

                                          }}
                                        >
                                          <div
                                            className="Project_Side"
                                            style={{
                                              display: "flex",
                                              backgroundColor: "#2f6eb2",
                                              color: "white",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                padding: "1rem",
                                                alignItems: "center",
                                                display: "flex",
                                              }}
                                            >
                                              {object.otitle}
                                            </h3>

                                            {projectObjDetails.length != 0 && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "10px",
                                                  alignItems: "center",
                                                  padding: "1rem",
                                                }}
                                              >
                                                <DirectionsIcon />

                                                {showAllMessage ? (
                                                  <h3
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                      fontSize: "1.4rem",
                                                    }}
                                                    onClick={() => {
                                                      setShowAllMessage(false);
                                                      setProjectObjDetails(
                                                        allMessages
                                                      );
                                                    }}
                                                  >
                                                    See All message
                                                  </h3>
                                                ) : (
                                                  <h3
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                      fontSize: "1.4rem",
                                                    }}
                                                    onClick={() => {


                                                      getProjectApprovedMessage();
                                                    }}
                                                  >
                                                    See approved message
                                                  </h3>
                                                )}
                                              </div>
                                            )}
                                          </div>

                                          {projectObjDetails == undefined ||
                                            projectObjDetails.length == 0 ? (
                                            <></>
                                          ) : (
                                            <>
                                              <div>
                                                {Object.keys(
                                                  groupedMessages
                                                ).map((date) => (
                                                  <div key={date}>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        borderBottom:
                                                          "1px solid lightgray",
                                                        margin: "2rem 0rem",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          position: "absolute",
                                                          left: "42%",
                                                          top: "-1.5rem",
                                                          background: "white",
                                                          padding:
                                                            "0.5rem 3rem",
                                                          borderRadius: "4rem",
                                                          border:
                                                            "1px solid lightgray",
                                                          fontSize: "1.3rem",
                                                        }}
                                                      >
                                                        {formatDate(date)}
                                                      </p>
                                                    </div>
                                                    {groupedMessages[date].map(
                                                      (projectobj) => (
                                                        <div>
                                                          <div
                                                            style={{


                                                              borderLeft: projectobj.objStatus ==
                                                                2 ? "3px solid #01a71b" : "",

                                                              backgroundColor: projectobj.objStatus ==
                                                                2 ? "#d9f8db" : "",
                                                              borderBottom:
                                                                "1px solid #cecece",

                                                              borderRadius:
                                                                "5px",
                                                            }}
                                                          >
                                                            <ListItem alignItems="flex-start">
                                                              <ListItemAvatar>
                                                                <Avatar


                                                                  style={{
                                                                    fontSize:
                                                                      "14px",


                                                                    backgroundColor:
                                                                      projectobj.uname
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase() ===
                                                                        "A"
                                                                        ? "#f44336"
                                                                        : projectobj.uname
                                                                          .charAt(
                                                                            0
                                                                          )
                                                                          .toUpperCase() ===
                                                                          "I"
                                                                          ? "#007bff"
                                                                          : "#ffa200",
                                                                  }}
                                                                >
                                                                  {projectobj.uname
                                                                    .charAt(0)
                                                                    .toUpperCase()}
                                                                </Avatar>
                                                              </ListItemAvatar>
                                                              <ListItemText
                                                                primary={
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      gap: "10px",
                                                                      marginBottom:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                        fontWeight:
                                                                          "500",

                                                                        color:
                                                                          "#333333",
                                                                        overflow:
                                                                          "auto",
                                                                      }}
                                                                    >
                                                                      {
                                                                        projectobj.uname
                                                                      }
                                                                    </p>

                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "12px",
                                                                        color:
                                                                          "#707070",
                                                                      }}
                                                                    >
                                                                      &#9679;
                                                                    </span>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                        color:
                                                                          "#969c9f",
                                                                        overflow:
                                                                          "auto",
                                                                      }}
                                                                    >
                                                                      {
                                                                        projectobj.postedTime
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                }
                                                                secondary={
                                                                  <div
                                                                    style={{
                                                                      fontSize:
                                                                        "16px",
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <p>
                                                                        {
                                                                          projectobj.message
                                                                        }
                                                                      </p>

                                                                      {projectobj.fileName && (
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            gap: "1rem",

                                                                            alignItems:
                                                                              "center",
                                                                            marginTop:
                                                                              "7px",
                                                                          }}
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              fontSize:
                                                                                "12px",
                                                                            }}
                                                                          >
                                                                            {
                                                                              projectobj.fileName
                                                                            }
                                                                          </p>

                                                                          <div>
                                                                            <VisibilityIcon
                                                                              style={{
                                                                                width:
                                                                                  "20px",
                                                                                height:
                                                                                  "20px",
                                                                                marginRight:
                                                                                  "5px",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={() => {

                                                                                openFile(
                                                                                  projectobj
                                                                                );
                                                                              }}
                                                                            />

                                                                            <DownloadIcon
                                                                              style={{
                                                                                width:
                                                                                  "20px",
                                                                                height:
                                                                                  "20px",
                                                                                marginRight:
                                                                                  "5px",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={() => {
                                                                                downloadFile(
                                                                                  projectobj
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      )}

                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          gap: "1rem",
                                                                          alignItems:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <IconButton
                                                                          edge="end"
                                                                          style={{
                                                                            padding:
                                                                              "10px 7px",
                                                                          }}
                                                                          onClick={() =>
                                                                            toggleReply(
                                                                              projectobj.id
                                                                            )
                                                                          }
                                                                        >
                                                                          <Reply className="replyIcon" />
                                                                        </IconButton>

                                                                        <p>
                                                                          reply
                                                                        </p>

                                                                        <p>
                                                                          {projectobj.reply ==
                                                                            undefined ||
                                                                            projectobj
                                                                              .reply
                                                                              .length ==
                                                                            0 ? (
                                                                            <>

                                                                            </>
                                                                          ) : (
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                gap: "10px",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                |
                                                                              </p>
                                                                              <a
                                                                                style={{
                                                                                  textDecoration:
                                                                                    "underline",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                href
                                                                                onClick={() =>
                                                                                  toggleAccordion(
                                                                                    projectobj.id
                                                                                  )
                                                                                }
                                                                              >
                                                                                {
                                                                                  projectobj
                                                                                    .reply
                                                                                    .length
                                                                                }
                                                                              </a>

                                                                              <p>
                                                                                reply
                                                                              </p>
                                                                            </div>
                                                                          )}
                                                                        </p>

                                                                        {projectobj.objStatus ==
                                                                          "2" ||
                                                                          projectobj.objStatus ==
                                                                          2 ? (

                                                                          <>
                                                                            <p>|</p>


                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                color:
                                                                                  "#06871b",
                                                                                border:
                                                                                  "1px solid #06871b",
                                                                                borderRadius:
                                                                                  "5px",
                                                                                backgroundColor:
                                                                                  "white",
                                                                              }}
                                                                            >



                                                                              <DoneIcon
                                                                                style={{
                                                                                  margin:
                                                                                    "auto",
                                                                                  height:
                                                                                    "2rem",
                                                                                  width:
                                                                                    "2rem",
                                                                                }}
                                                                              />



                                                                              <p
                                                                                style={{
                                                                                  marginRight:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                Approved
                                                                              </p>
                                                                            </div>
                                                                          </>



                                                                        ) : null}

                                                                        {projectobj.objStatus ==
                                                                          "3" ||
                                                                          projectobj.objStatus ==
                                                                          3 ? (
                                                                          <>
                                                                            <p>|</p>

                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                color:
                                                                                  "red",
                                                                                border:
                                                                                  "1px solid red",
                                                                                borderRadius:
                                                                                  "5px",
                                                                              }}
                                                                            >
                                                                              <ClearIcon
                                                                                style={{
                                                                                  height:
                                                                                    "2rem",
                                                                                  width:
                                                                                    "2rem",
                                                                                }}
                                                                              />
                                                                              <p
                                                                                style={{
                                                                                  marginRight:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                Rejected
                                                                              </p>
                                                                            </div></>
                                                                        ) : null}

                                                                        {projectobj.objStatus ==
                                                                          "1" ||
                                                                          projectobj.objStatus ==
                                                                          1 ? (
                                                                          <>

                                                                            <p>|</p>
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                color:
                                                                                  "green",
                                                                                border:
                                                                                  "1px solid orange",

                                                                                borderRadius:
                                                                                  "5px",
                                                                              }}
                                                                            >
                                                                              <ClockOrange
                                                                                className="clock_size__O"
                                                                                cls1={
                                                                                  "cls1_s"
                                                                                }
                                                                                cls2={
                                                                                  "cls2_s"
                                                                                }
                                                                              />
                                                                              <p
                                                                                style={{
                                                                                  marginRight:
                                                                                    "10px",
                                                                                  color:
                                                                                    "orange",
                                                                                }}
                                                                              >
                                                                                Inprogress
                                                                              </p>
                                                                            </div>

                                                                          </>
                                                                        ) : null}
                                                                      </div>

                                                                      {(showReply ===
                                                                        index ||
                                                                        showReply ===
                                                                        projectobj.id) && (
                                                                          <div>
                                                                            <ListItem alignItems="flex-start">
                                                                              <ListItemAvatar>
                                                                                <Avatar


                                                                                  style={{
                                                                                    fontSize:
                                                                                      "14px",

                                                                                    backgroundColor:
                                                                                      userDetails.name
                                                                                        .charAt(
                                                                                          0
                                                                                        )
                                                                                        .toUpperCase() ===
                                                                                        "A"
                                                                                        ? "#f44336"
                                                                                        : userDetails.name
                                                                                          .charAt(
                                                                                            0
                                                                                          )
                                                                                          .toUpperCase() ===
                                                                                          "I"
                                                                                          ? "#007bff"
                                                                                          : "#ffa200",
                                                                                  }}
                                                                                >
                                                                                  {userDetails.name
                                                                                    .charAt(
                                                                                      0
                                                                                    )
                                                                                    .toUpperCase()}
                                                                                </Avatar>
                                                                              </ListItemAvatar>
                                                                              <ListItemText
                                                                                primary={
                                                                                  <p
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "small",
                                                                                      color:
                                                                                        "#3372B5",
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      userDetails.name
                                                                                    }
                                                                                  </p>
                                                                                }
                                                                                secondary={
                                                                                  <OutlinedInput
                                                                                    style={{
                                                                                      margin:
                                                                                        "15px",
                                                                                      width:
                                                                                        "97%",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                      backgroundColor:
                                                                                        "white",
                                                                                    }}
                                                                                    placeholder="Reply..."
                                                                                    value={
                                                                                      replyMessage
                                                                                    }
                                                                                    onChange={(
                                                                                      e
                                                                                    ) => {
                                                                                      handleReplyMessageChange(
                                                                                        e
                                                                                      );
                                                                                    }}
                                                                                    onKeyDown={
                                                                                      handleKeyPress
                                                                                    }
                                                                                    endAdornment={
                                                                                      <InputAdornment position="end">
                                                                                        <IconButton edge="end">
                                                                                          <SendIcon
                                                                                            style={{
                                                                                              color:
                                                                                                "#3372b5",
                                                                                              height:
                                                                                                "2.5rem",
                                                                                              width:
                                                                                                "2.5rem",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                              postReply(
                                                                                                object.oid,
                                                                                                projectobj.id,
                                                                                                "reply"
                                                                                              );
                                                                                            }}
                                                                                          />
                                                                                        </IconButton>
                                                                                      </InputAdornment>
                                                                                    }
                                                                                  />
                                                                                }
                                                                              />
                                                                            </ListItem>
                                                                          </div>
                                                                        )}

                                                                      {projectobj.reply ==
                                                                        undefined ||
                                                                        projectobj
                                                                          .reply
                                                                          .length ==
                                                                        0 ? (
                                                                        <></>
                                                                      ) : (
                                                                        <>
                                                                          {projectobj.reply.map(
                                                                            (
                                                                              reply,
                                                                              index
                                                                            ) => (
                                                                              <div
                                                                                key={
                                                                                  index
                                                                                }
                                                                                className="accordion"
                                                                              >
                                                                                {(openIndex ===
                                                                                  index ||
                                                                                  openIndex ===
                                                                                  projectobj.id) && (
                                                                                    <div
                                                                                      className="accordion-content"
                                                                                      style={{
                                                                                        width:
                                                                                          "60%",
                                                                                      }}
                                                                                    >
                                                                                      <ListItem alignItems="flex-start">
                                                                                        <ListItemAvatar>
                                                                                          <Avatar


                                                                                            style={{
                                                                                              fontSize:
                                                                                                "14px",

                                                                                              backgroundColor:
                                                                                                reply.uname
                                                                                                  .charAt(
                                                                                                    0
                                                                                                  )
                                                                                                  .toUpperCase() ===
                                                                                                  "A"
                                                                                                  ? "#f44336"
                                                                                                  : reply.uname
                                                                                                    .charAt(
                                                                                                      0
                                                                                                    )
                                                                                                    .toUpperCase() ===
                                                                                                    "I"
                                                                                                    ? "#007bff"
                                                                                                    : "#ffa200",
                                                                                            }}
                                                                                          >
                                                                                            {reply.uname
                                                                                              .charAt(
                                                                                                0
                                                                                              )
                                                                                              .toUpperCase()}
                                                                                          </Avatar>
                                                                                        </ListItemAvatar>
                                                                                        <ListItemText
                                                                                          primary={
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "small",
                                                                                                color:
                                                                                                  "#3372B5",
                                                                                                marginBottom:
                                                                                                  "5px",
                                                                                              }}
                                                                                            >
                                                                                              {
                                                                                                reply.uname
                                                                                              }
                                                                                            </p>
                                                                                          }
                                                                                          secondary={
                                                                                            <div
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "medium",
                                                                                                color:
                                                                                                  "",
                                                                                              }}
                                                                                            >
                                                                                              {
                                                                                                reply.message
                                                                                              }
                                                                                            </div>
                                                                                          }
                                                                                        />
                                                                                      </ListItem>
                                                                                      <hr />
                                                                                    </div>
                                                                                  )}
                                                                              </div>
                                                                            )
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                }
                                                              />
                                                            </ListItem>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                ))}
                                              </div>
                                            </>
                                          )}
                                          {object.userOP.op != 2 && (
                                            <OutlinedInput
                                              style={{
                                                margin: "15px",
                                                display: "flex",
                                                fontSize: "14px",
                                                backgroundColor: "white",
                                              }}
                                              placeholder="Message..."
                                              value={message}
                                              onChange={(e) => {
                                                handleMessageChange(e);
                                              }}
                                              onKeyDown={handleKeyPress}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {object.fileEnable ==
                                                      "true" && (
                                                        <div>
                                                          <input
                                                            className={
                                                              classes.hidden
                                                            }

                                                            accept="image/*,application/pdf,"
                                                            type="file"
                                                            onChange={(e) => {
                                                              handleImageChange(
                                                                e,
                                                                object.oid
                                                              );
                                                            }}
                                                            ref={hiddenFileInput}
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          />

                                                          <Avatar
                                                            style={{
                                                              background:
                                                                "transparent",

                                                              cursor: "pointer",
                                                            }}
                                                            onClick={handleClick}
                                                          >
                                                            <AttachFileIcon
                                                              style={{
                                                                rotate: "none",
                                                                transform:
                                                                  "rotate(30deg)",
                                                                color: "#3f51b5",
                                                                height: "2.5rem",
                                                                width: "2.5rem",
                                                              }}
                                                            />
                                                          </Avatar>
                                                        </div>
                                                      )}

                                                    <Typography
                                                      className={classes.title}
                                                      variant="h6"
                                                    ></Typography>
                                                    <p
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        paddingLeft: "3%",
                                                      }}
                                                    ></p>
                                                  </div>
                                                  <IconButton edge="end">
                                                    <SendIcon
                                                      style={{
                                                        color: "#3372b5",
                                                        height: "2.5rem",
                                                        width: "2.5rem",
                                                      }}
                                                      onClick={() => {
                                                        projectObj(object.oid);
                                                      }}
                                                    />
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                            />
                                          )}

                                          {selectedFile && (
                                            <div
                                              style={{
                                                alignItems: "center",
                                                display: "flex",
                                                gap: "8px",
                                                fontSize: "12px",

                                                padding: "5px",
                                                margin:
                                                  "0 0 10px 20px",
                                              }}
                                            >
                                              <p>{selectedFile.name}</p>


                                              <CloseIcon
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                  height: "2rem",
                                                  width: "2rem",
                                                }}
                                                onClick={() => {
                                                  setSelectedFile(null);
                                                  setErrMessage("");
                                                }}
                                              />
                                            </div>
                                          )}

                                          {errMessage && (
                                            <p
                                              style={{
                                                color: "red",
                                                margin: "0 0 10px 20px",

                                              }}
                                            >
                                              {errMessage}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </TabPanel>
                                  ))}
                                </>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Typography>
                    </AccordionDetails>
                  ))}
              </Accordion>
            )
          )}
        </>
      ))}


    </div>
  );
};

export default Project;
